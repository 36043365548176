.number-users {
  min-height: 150px;
  width: 15vw;
  height: auto;
  background-color: #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.title {
  color: #9D9D9D;
  padding-left: 2%;
  padding-top: 2%;
  font-size: 1vw;
}

.number {
  color: #8947f4;
  width: 100%;
  font-size: 7vw;
  text-align: center;
  height: 20vh;
  min-height: 100px;
}

.icons {
  color: #8947f4;
}

.container-icon {
  justify-content: flex-end;
  width: 90%;
  display: flex;
  height: 5vh;
}
