.App {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grid-info {
  display: flex;
  width: 70vw;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.container-grafics {
  margin-bottom: 50px;
}

.number-users {
  min-height: 150px;
  width: 20vw;
  height: auto;
  background-color: #8947f4;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.title {
  color: white;
  padding-left: 2%;
  padding-top: 2%;
  font-size: 1vw
}

.title-white {
  color: #9D9D9D;
  padding-left:15px;
  padding-top: 15px;
}

.number {
  color: white;
  width: 100%;
  font-size: 7vw;
  text-align: center;
  height: 20vh;
  min-height: 100px;
}

.grafic-users {
  width: 60vw;
  height: 20vw;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  background-color: white;
}

.icons {
  color: white;
}

.container-icon {
  justify-content: flex-end;
  width: 90%;
  display: flex;
  height: 5vh;
}

.column {
  width: 100%;
  padding-left: 2%;
  padding-top: 2%;
}

.column text {
  color: #9d9d9d;
}

.grafic-line {
  display: flex;
  flex-direction: column;
  width: 70vw;
  background-color: white;
  border-radius: 15px;
  margin-top: 25px;
}

.btn {
  outline: none;

  border: none;
  background-color: #8947f4;
  color: white;
  font-size: 1.2rem;
  margin: 2%;
  width: 150px;
  height: 50px;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}

.btn:hover {
  box-shadow: 2px 2px 5px 1px #dbdbdb;
  transition: 0.3s ease-in-out;
  scale: 1.05;
  background-color: #6e14ff;
}

.title-module{
  margin-top: 1.5%;
  font-size: 2vw;
  color: #6e14ff;
  font-weight: 600;
  width: 70vw;

}

.label{
  font-weight: 600;
}