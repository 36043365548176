.buttonConfirmed{
    margin-right: 25px !important;
  }
  
  .buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 7%;
  }
  
  .inputConfirmation{
    background-color: #F3F3F3;
    border: none;
    outline: none;
    border-radius: 0.5vw 0.5vw 0 0;
    margin-top: 2vh;
    padding-left: 4%;
  }
  
  .modalStyle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    border: 2px solid #000;
    box-shadow: 24;
    border-radius: 8px;
    text-align: center;
  }
  /* p: 4;
  bgcolor: 'background.paper'; */
  
  .App {
    width: calc(100% - 60px);
    height: 90%;
    position: fixed;
    overflow-y: scroll;
    background-color: white;
    margin-top: 30px;
    padding: 5px;
  }
  
  .App::-webkit-scrollbar {
    display: none;
  }
  
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  strong {
    color: red;
  }
  
  a {
    font-weight: 700;
  }
  