p{
  height: 5px;
}

.containerInput{
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-evenly !important;
  margin-bottom: 1rem !important;
}


.inputCode{
  width: 10% !important;
  outline: none !important;
  border: 0 solid #d4d4d4 !important;
  border-bottom: 1px solid #d4d4d4 !important;
  background-color: #f1f1f1;
}

.textInformation{
  margin-top: 2rem;
}

.Title{
  font-size: 2rem;
  font-weight: 700;
  width: 100%;
  text-align: center;

}

.Button{
    width: 100%;
    height: 3rem;
    background-color: #1E1E1E;
    color: white;    
    outline: none;
    border: none; 
    font-size: 1.30rem;
 }
 
 .logo-div {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 
 .logo {
  width: 10vw;
  height: 20vh;
}
 
 .Data-Login {
   width: 60%;
   height: 70%;
   padding-left: 5%;
   padding-right: 5%;
   padding-bottom: 5%;
   background-color: white;
   border-radius: 2rem;
   box-shadow: 5px 5px 25px 2px #d4d4d4;
   display: grid;
   grid-template-columns: 1fr;
   min-width: 200px;
 }
 
 .Form,
 .Image {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 
 .Form {
   background-color: #ececec;
 }
 
 .Image {
   contain: content;
 }
 
 .Image-Render {
   width: 100%;
   height: 100%;
   object-fit: fill;
 }
 
 .App {
   width: 100vw;
   height: 100vh;
   display: grid;
   grid-template-columns: 40% 60%;
 }
 