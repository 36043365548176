.saudacao,
img,
.Image{
    display: flex;
    width: auto;
    align-items: center;
}

.saudacao {
  height: 70px;
  justify-content: start;
  text-align: center;
  font-size: 1.25rem;
  color: #281775;
}

img {
  height: 20px;
}

.Image {
  padding: auto;
  height: 70px;
  margin-left: 5rem;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10%;
}
