.Title{
  font-size: 2rem;
  font-weight: 700;
  width: 100%;
  text-align: center;
}

.recoverPass{
  cursor: pointer !important;
  user-select: none;
}

.Button{
   width: 100%;
   height: 3rem;
   background-color: #1E1E1E;
   color: white;    
   outline: none;
   border: none; 
   font-size: 1.30rem;
}

.logo-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 10vw;
  height: 20vh;
}

.Data-Login {
  width: 60%;
  height: 70%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  background-color: white;
  border-radius: 2rem;
  box-shadow: 5px 5px 25px 2px #d4d4d4;
  display: grid;
  grid-template-columns: 1fr;
  
}

.Form,
.Image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Form {
  background-color: #ececec;
}

.Image {
  contain: content;
}

.Image-Render {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.App {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 40% 60%;
}
