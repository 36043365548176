.buttonConfirmed {
  margin-right: 25px !important;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 7%;
}



.inputConfirmation {
  background-color: #f3f3f3;
  border: none;
  outline: none;
  border-radius: 0.5vw 0.5vw 0 0;
  margin-top: 2vh;
  padding-left: 4%;
}

.modalStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  border: 2px solid #000;
  box-shadow: 24;
  border-radius: 8px;
  text-align: center;
}
/* p: 4;
  bgcolor: 'background.paper'; */

  .App {
    width: calc(100% - 60px);
    height: 90%;
    position: fixed;
    overflow-y: scroll;
    background-color: white;
    margin-top: 30px;
    padding: 5px;
  }

.App::-webkit-scrollbar {
  display: none;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

strong {
  color: red;
}

a {
  font-weight: 700;
}

.imagemMokcup {
  width: 30vh;
  height: 60vh;
  background-image: url("../../images//mockup_iphone.png");
  background-size: cover; 
  background-position: center;
  padding: 1.1vw;
}


.main-smartphone{
    width: 100%;
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-smartphone a{
    cursor: pointer;
}

.button{
    width: 50%;
    height: 3.5vw;
    background-color: #2c2c2c;
    border-radius: 0.9vw;
    margin-top: 1vw;
    color: white
}
.button-envio-mensagem{
    width: 100%;
    height: 2vw;
    background-color: #2c2c2c;
    border-radius: 0.3vw;
    margin-top: 1vw;
    color: white;
    font-size: 0.7vw;
}

.title-smartphone{
    font-size: 2vw;
    font-weight: 600;
    margin-bottom: 1vw;
}

.background-mockup{
    width: 100%;
    height: 95%;
    background-color: white;
    border-radius: 15px;
    padding: 2%;
}

.title{
    font-size: 0.8rem;
    
    margin-top: 1.5vw;
}

.layer,
.layer-mina{
    width: 100%;
    height: 4vw;
    box-shadow: 1px 2px 5px 0px #bebebe;
    border-radius: 5px;
    padding: 4%;
    display: flex;
    margin-top: 1.5vw;
    flex-direction: column;
}

.layer-mina{
    height:  20vw !important;
    gap: 15px;
}

.option-mina,
.level1,
.level2,
.level3,
.teste{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #623ad4;
    border-radius: 5px;
    cursor: pointer;
}

.level1{
    background-color: rgb(255, 213, 0);
}

.level2{
    background-color: orange;
}

.level3{
    background-color: red;
}


.input-mensagem{
    width: 100%;
    height: 8vw;
    border: 1px solid #bebebe;
    padding: 2%;
    border-radius: 5px;
    display: flex;
    justify-content: start;
    align-items: start;
    text-align: start;
}
