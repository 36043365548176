.body {
  width: calc(100% - 75px);
  height: calc(100% - 71px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 50px;
}

.body-Data {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  width: 100%;
  height: 70px;
  background-color: white;
}

.BodyHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BodyHeader-Data {
  width: calc(100% - 75px);
  height: 100%;
  margin-left: 75px;
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow-y: scroll;
}

.BodyHeader,
.menubar {
  width: 100%;
  height: 100%;
}

.menubar{
  z-index: 999;
  border-right: 60px solid #f0f0f8;
}

.App {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 60px auto;
  background-color: #f0f0f8;
}
