.column-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  padding-left: 2.5%;
  padding-right: 2.5%;
  width: 95%;
  margin-top: 15px;
}

.body-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title-section {
  width: 95%;
  height: 50px;
  max-height: 50px;
  text-align: start;
  font-size: 1.5rem;
  padding-left: 2rem;
  border-bottom: 1px solid #000;
}

.App,
.container-access-data,
.container-personal-data {
  display: flex;
  flex-direction: column;
}

.container-access-data,
.container-personal-data {
  width: 100%;
  height: 100%;
}

.App {
  width: 100%;
  height: 95%;
}
